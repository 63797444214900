import clsx from 'clsx';
import Image from 'next/image';

import { AhotuImageProps } from '../../types/mediaTypes';

interface BackgroundImageProps {
  children?: React.ReactNode;
  image: any;
  className?: string;
  sizes: string;
  alt: string;
  priority?: boolean;
  objectFit?: 'contain' | 'cover';
}

const BackgroundImage = ({
  children,
  image,
  className,
  sizes,
  alt,
  priority = false,
  objectFit = 'cover'
}: BackgroundImageProps) => {
  let ahotuImage = null;
  //eslint-disable-next-line
  if (image !== undefined && image.hasOwnProperty('key')) ahotuImage = image as AhotuImageProps;
  return (
    <div className={clsx('relative w-full h-full', className)}>
      {ahotuImage && (
        <Image
          priority={priority}
          layout="fill"
          objectFit={objectFit}
          src={`https://images.ahotu.com/${ahotuImage.key}?w=1920&f=webp`}
          sizes={sizes}
          alt={alt}
          placeholder="blur"
          blurDataURL={ahotuImage.base64}
        />
      )}
      {!ahotuImage && (
        <Image layout="fill" objectFit="cover" alt="fallback image" src={image as string | 'StaticImport'} />
      )}
      {children && <div className="w-full h-full relative">{children}</div>}
    </div>
  );
};

export default BackgroundImage;
