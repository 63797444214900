import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import { getPartnerLogos, PartnerLogo } from '~/src/lib/getPartnerLogos';

import AdBanner from '../components/atoms/AdBanner';
import InfoText from '../components/atoms/homepage/InfoText';
import Spotlights from '../components/molecules/homepage/Spotlights';
import ContinentList from '../components/organisms/ContinentList';
import FeaturedEvents from '../components/organisms/FeaturedEvents';
import HomepageHeader from '../components/organisms/HomepageHeader';
import SportsGrid from '../components/organisms/SportsGrid';
import USPBar from '../components/organisms/USPBar';
import { translateRecursively } from '../gql-queries/localizeGQLResults';
import { withErrorHandling } from '../hoc/withErrorHandling';
import getHomePageData from '../lib/getHomePageData';
import getLocalesData from '../lib/getLocalesData';
import AhotuLayout from '../templates/AhotuLayout';
import { AhotuSection } from '../templates/AhotuSection';
import { DropdownLink } from '../types/eventPageTypes';
import { TranslatedText } from '../types/GeneralTypes';
import {
  AdBannerType,
  ContinentSuggestionData,
  HeaderType,
  SportGridElement,
  SpotlightsType,
  TopNavigationMenu,
  USPData
} from '../types/homePageTypes';
import { AhotuImageProps } from '../types/mediaTypes';
import { FeaturedEvent } from '../types/sportPageTypes';
import { hasOneOrMore } from '../utils/arrays';
import getTopNavigationMenu from '../utils/getTopNavigationMenu';
import useMediaQuery from '../utils/useMediaQuery';

export interface IHomePageProps {
  featuredEvents: FeaturedEvent[];
  //sponsoredEvents: (SponsoredEvent)[];
  continents: ContinentSuggestionData[];
  categories: (SportGridElement & { image: AhotuImageProps; name: string; docCount: number; url: string })[];
  //headerImage: AhotuImageProps; not used?
  USPData: USPData;
  aboutUs: string;
  otherLanguagesUrls: DropdownLink[];
  schemaData: object;
  //slogan: string; not used?
  spotlights: SpotlightsType[];
  header: HeaderType;
  adBanner: AdBannerType;
  infoText: TranslatedText;
  topNavigationMenu: TopNavigationMenu;
  partnerLogos: PartnerLogo[];
}

const Homepage = ({
  header,
  featuredEvents,
  adBanner,
  infoText,
  continents,
  categories,
  USPData,
  otherLanguagesUrls,
  schemaData,
  spotlights,
  topNavigationMenu,
  partnerLogos
}: IHomePageProps) => {
  const isMobile = useMediaQuery('lg');
  const { t } = useTranslation();

  return (
    <AhotuLayout
      partnerLogos={partnerLogos}
      animateNavigation={false}
      topNavigationMenu={topNavigationMenu}
      headProps={{
        title: t('home-page:page-title'),
        description: t('home-page:page-meta-description', {
          sportsCount: USPData.sports,
          countriesCount: USPData.countries
        }),
        schemaData,
        image: header.image
      }}
      otherLanguagesUrls={otherLanguagesUrls}>
      <HomepageHeader header={header} />

      <USPBar data={USPData} />

      <SportsGrid categories={categories} />

      <div className="flex flex-col space-y-20 pt-20">
        {hasOneOrMore(featuredEvents) && (
          <AhotuSection layout="left-container" id="featured-events-list">
            <FeaturedEvents events={featuredEvents} useOn="homepage" fromSection="featured_section" />
          </AhotuSection>
        )}

        {infoText && <InfoText text={infoText} />}

        {adBanner.enabled && (
          <AdBanner size={isMobile ? 'rectangle' : 'leaderboard'} category="mainpage" adBanner={adBanner} />
        )}
        <AhotuSection id="sponsored events" layout="container">
          <Spotlights spotlights={spotlights} />
        </AhotuSection>

        <AhotuSection layout="left-container" id="continent-list">
          <ContinentList continents={continents} />
        </AhotuSection>
      </div>
    </AhotuLayout>
  );
};

export const getServerSideProps = withErrorHandling(async context => {
  const { locale, locales } = context;

  const homePageData = await getHomePageData(context, locale);
  const homePageDataLocalized = translateRecursively(homePageData, locale); //FIXME: get default locale from a single source of truth

  const props = {
    ...(await serverSideTranslations(context.locale, [
      'common',
      'footer',
      'header',
      'home-page',
      'session',
      'validation'
    ])),
    ...homePageDataLocalized,
    partnerLogos: await getPartnerLogos(),
    topNavigationMenu: await getTopNavigationMenu(locale),
    otherLanguagesUrls: await getLocalesData(locales, 'homepage', locale)
  }; //TODO: type this properly

  return {
    props
  };
});

export default Homepage;

// This is need for standalone nextjs build with newrelic and i18nexus
const newrelicDeps = [
  '@newrelic/next',
  'newrelic',
  'semver',
  'json-stringify-safe',
  'readable-stream',
  'inherits',
  'util-deprecate',
  'lru-cache',
  'yallist',
  'async',
  'concat-stream',
  'buffer-from',
  'https-proxy-agent',
  'debug',
  'ms',
  'agent-base',
  '@tyriar/fibonacci-heap'
];

export const config = {
  unstable_includeFiles: [
    'newrelic.js',
    './public/locales/**/*.+(js|json)',
    './public/**/*.*',
    ...newrelicDeps.map(d => `node_modules/${d}/**/*.+(js|json)`)
  ]
};
